import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo_dark.svg";
import $ from "jquery";
import config from "../lib/config";
import Web3 from "web3";
import { toast } from "react-toastify";
import busdContABI from "../Abi/BusdContractAbi.json";
import XTRCABI from "../Abi/XTrcABI.json";
//
import {
  useEthersSigner,
  walletClientToSigner
} from "../pages/ethersConnect.js";

import {
  useWalletClient,
  usePublicClient,
  useDisconnect,
  useConnect,
  useNetwork,
  useAccount,
  useSwitchNetwork
} from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../reducers/Actions";
import { getUserDetails } from "../Action/ApiAction";
let toasterOption = config.toasterOption;

export default function Navbar({ paramsData }) {
  useEffect(() => {
    loadScript();
  }, []);
  console.log(paramsData, "paramsData");
  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      10 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      20 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      30 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  const walletConnection = useSelector((state) => state.walletConnection);
  console.log(walletConnection, "walletConnection");
  const dispatch = useDispatch();
  const [metamaskenable, setmetamaskenable] = useState(true);
  const [blns, setBln] = useState(0);
  const [tokBal, setTokenBalance] = useState();
  const [tokBal1, setTokenBalance1] = useState();
  const [userdata, setuserdata] = useState([]);

  const network = useSwitchNetwork({
    onMutate(args) {
      console.log("Mutate", args);
    },
    onError(error) {
      console.log("Error", error);
    }
  });

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log(accounts, "accountsef");
        window.location.reload();
      });
    }
  }, []);

  useAccount({
    onConnect: ({ address, connector, isReconnected }) => {
      console.log("Connected", { address, connector, isReconnected });
    },
    onDisconnect: () => {
      console.log("Disconnected");
      var web3 = new Web3(config.rpcUrl);
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: ""
        })
      );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    }
  });

  const { disconnect } = useDisconnect();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const { chain, chains } = useNetwork();

  let chainId = config.NetworkId;
  console.log(chainId, "chainIdcccccc", chain);
  const { data: walletClient } = useWalletClient({ chainId });

  useEffect(() => {
    setConnection();
  }, [walletClient, chain?.network]);

  useEffect(() => {
    getconnect();
    getuserdata();
  }, [walletConnection, walletClient]);

  async function getconnect() {
    if (
      walletConnection &&
      walletConnection.web3 &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != "" &&
      walletConnection.network != ""
    ) {
      let address = walletConnection.address;
      let provider = walletConnection.provider;

      if (provider) {
        var web3 = new Web3(provider);
        try {
          if (typeof provider !== "undefined") {
            //getBNB balance
            let balgetAddr = paramsData ? paramsData : walletConnection.address;
            var balance = await web3.eth.getBalance(balgetAddr);
            console.log(balance, "balance");

            var currbal = balance / 1e18;
            setBln(currbal.toFixed(4));

            const ContractToken = new web3.eth.Contract(
              busdContABI,
              config.Busdaddress
            );
            const ContractToken1 = new web3.eth.Contract(
              XTRCABI,
              config.xtrcaddress
            );

            let tokenBal = await ContractToken.methods
              .balanceOf(balgetAddr)
              .call();
            let wMaticBal = tokenBal / 1e18;
            setTokenBalance(wMaticBal);
            let tokenBal1 = await ContractToken1.methods
              .balanceOf(balgetAddr)
              .call();
            let wMaticBal1 = tokenBal1 / 1e18;
            setTokenBalance1(wMaticBal1);
          }
        } catch (err) {}
      }
    }
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  async function setConnection() {
    console.log("RRRRRRRRRRRRRRRRRRRR", walletClient);

    if (walletClient && chain && chain.id == config.NetworkId) {
      var { signer, transport } = walletClientToSigner(walletClient);
      console.log("transportsssssssssss: ", transport);
      var web3 = new Web3(transport);

      console.log("userdetails: ", {
        network: config.NetworkId,
        web3: web3,
        address: walletClient.account.address,
        provider: transport,
        connect: "yes",
        isChange: "false"
      });
      console.log(
        config.NetworkId,
        chain.id,
        "config.NetworkIdconfig.NetworkId"
      );

      if (chain.id == config.NetworkId) {
        dispatch(
          setWallet({
            network: config.NetworkId,
            web3: web3,
            address: walletClient.account.address,
            provider: transport,
            connect: "yes",
            isChange: "false"
          })
        );
        localStorage.setItem("iswalletconnect", "yes");
      }

      console.log(signer, transport, "@@@@@@@@@@@@@@@@@@@1");
    } else {
      var web3 = new Web3("https://bsc-dataseed1.binance.org");
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: ""
        })
      );
    }
  }

  async function connectfunction(connector) {
    try {
      let check = isMobile();

      if (
        check &&
        !window.ethereum &&
        connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();
        if (
          parseInt(currnetwork) != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          toast.error("Please connect smart chain", toasterOption);
          return;
        }
      }

      var resp = connect(connector);
      window.$("#connect_wallet_modal").modal("hide");
      setTimeout(function () {
        //window.location.reload(false);
      }, 1000);
    } catch (err) {}
  }

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    // console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

  async function disconnectMetamask() {
    localStorage.clear();

    disconnect();

    setTimeout(function () {
      window.location.reload(false);
    }, 1000);
  }

  const getuserdata = async () => {
    var reqdata = {
      address: walletConnection.address
    };

    const data = await getUserDetails(reqdata);
    console.log(data, "datadatadatadata");

    if (data?.data?.success == true) {
      console.log(userdata, "userdatauserdatauserdata");
      setuserdata(data?.data?.data);
    } else {
      setuserdata([]);
    }
  };

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  {paramsData ? (
                    <>
                      {walletConnection && walletConnection.address != "" ? (
                        <li class="nav-item">
                          <div class="dropdown">
                            <button
                              class="btn primary_btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Choose Dashboard
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                {/* {userdata.length !== 0 ? ( */}
                                <>
                                  <Link
                                    class="dropdown-item"
                                    to={`/dashboard-community/${paramsData}`}
                                  >
                                    Community
                                  </Link>
                                  <Link
                                    class="dropdown-item"
                                    to={`/dashboard-member/${paramsData}`}
                                  >
                                    Member
                                  </Link>
                                </>
                                {/* ) : (
                                  <></>
                                )} */}
                              </li>
                            </ul>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {walletConnection && walletConnection.address != "" ? (
                        <li class="nav-item">
                          <div class="dropdown">
                            <button
                              class="btn primary_btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Choose Dashboard
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                {userdata.length !== 0 ? (
                                  <Link
                                    class="dropdown-item"
                                    to="/dashboard-community"
                                  >
                                    Community
                                  </Link>
                                ) : (
                                  // <Link class="dropdown-item" to="/join-community">
                                  //   Community
                                  // </Link>
                                  <></>
                                )}
                              </li>
                              <li
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                {userdata.length != 0 &&
                                userdata.communityOnly == 1 ? (
                                  <Link
                                    class="dropdown-item"
                                    to="/dashboard-member"
                                  >
                                    Member
                                  </Link>
                                ) : userdata.communityOnly == 0 ? (
                                  <Link
                                    class="dropdown-item"
                                    to="/dashboard-community"
                                  >
                                    Community
                                  </Link>
                                ) : (
                                  <Link class="dropdown-item" to="/join-now">
                                    Member
                                  </Link>
                                )}
                              </li>
                            </ul>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {walletConnection && walletConnection.address == "" ? (
                    <li class="nav-item">
                      <button
                        className="primary_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#connect_wallet_modal"
                      >
                        Connect Wallet
                      </button>
                    </li>
                  ) : (
                    <>
                      {/* After Login Menus */}
                      <li class="nav-item">
                        <div className="dropdown me-3">
                          <a
                            className="primary_btn dropdown-toggle address_btn"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {paramsData
                              ? halfAddrShow(paramsData)
                              : halfAddrShow(walletConnection.address)}{" "}
                          </a>
                          <ul
                            className="dropdown-menu walletdrop mt-2 "
                            aria-labelledby="dropdownMenuLink"
                          >
                            <li>
                              <a className="dropdown-item" href="#">
                                <label>BNB Balance:</label>
                                <span className="ms-2">{blns} </span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                style={{ whiteSpace: "normal" }}
                                href="#"
                              >
                                <label>TAURAS Balance: </label>
                                <span className="ms-0 d-block">
                                  {" "}
                                  {tokBal?.toFixed(4)}{" "}
                                </span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                style={{ whiteSpace: "normal" }}
                                href="#"
                              >
                                <label>XTAURAS Balance: </label>
                                <span className="ms-0 d-block">
                                  {" "}
                                  {tokBal1?.toFixed(4)}{" "}
                                </span>
                              </a>
                            </li>
                            {/* <li>
                              <a className="dropdown-item" href="#">
                                Dashboard
                              </a>
                            </li> */}
                            {/* <button className="primary_btn">Logout</button>{" "} */}

                            <li class="nav-item" data-bs-dismiss="offcanvas">
                              <a
                                class="nav-link"
                                href="#"
                                onClick={() => disconnectMetamask()}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                >
                                  <path
                                    d="M14.548 0.0984906C14.5313 0.0971525 14.5173 0.0884552 14.5 0.0884552H7.33333C6.23067 0.0884552 5.33333 0.988964 5.33333 2.09553V2.76456C5.33333 3.13386 5.632 3.43358 6 3.43358C6.368 3.43358 6.66667 3.13386 6.66667 2.76456V2.09553C6.66667 1.7269 6.966 1.42651 7.33333 1.42651H10.4393L10.236 1.49475C9.696 1.68207 9.33333 2.19254 9.33333 2.76456V12.7999H7.33333C6.966 12.7999 6.66667 12.4995 6.66667 12.1309V10.7929C6.66667 10.4236 6.368 10.1238 6 10.1238C5.632 10.1238 5.33333 10.4236 5.33333 10.7929V12.1309C5.33333 13.2375 6.23067 14.138 7.33333 14.138H9.33333V14.807C9.33333 15.545 9.93133 16.1451 10.6667 16.1451C10.8093 16.1451 10.9447 16.1243 11.0913 16.0788L15.0967 14.7388C15.6373 14.5514 16 14.041 16 13.469V1.42651C16 0.646423 15.33 0.0349332 14.548 0.0984906Z"
                                    fill="#E9D16F"
                                  />
                                  <path
                                    d="M7.138 6.30571L4.47133 3.62961C4.28067 3.43827 3.994 3.38073 3.74467 3.48443C3.496 3.58813 3.33333 3.83232 3.33333 4.10261V6.10968H0.666667C0.298667 6.10968 0 6.40941 0 6.77871C0 7.14801 0.298667 7.44774 0.666667 7.44774H3.33333V9.45481C3.33333 9.7251 3.496 9.96929 3.74467 10.073C3.994 10.1767 4.28067 10.1192 4.47133 9.92781L7.138 7.25171C7.39867 6.99012 7.39867 6.5673 7.138 6.30571Z"
                                    fill="#E9D16F"
                                  />
                                </svg>
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </>
                  )}

                  <Link
                    class="btn primary_btn"
                    target="_blank"
                    style={{ marginLeft: "12px" }}
                    type="button"
                    id="dropdownMenuButton1"
                    to="https://tauraspresale.vercel.app/"
                  >
                    Buy TRC
                  </Link>

                  {(userdata?.userID || paramsData) && (
                    <Link
                      class="btn primary_btn"
                      target="_blank"
                      style={{ marginLeft: "12px" }}
                      type="button"
                      id="dropdownMenuButton1"
                      to="https://trcswap.vercel.app/"
                    >
                      Sell TRC
                    </Link>
                  )}


                  <Link
                    class="btn primary_btn"
                    target="_blank"
                    style={{ marginLeft: "12px" }}
                    type="button"
                    id="dropdownMenuButton1"
                    to="https://xtauraspresale.vercel.app/"
                  >
                    Buy XTRC
                  </Link>

                  {(userdata?.userID || paramsData) && (
                    <Link
                      class="btn primary_btn"
                      target="_blank"
                      style={{ marginLeft: "12px" }}
                      type="button"
                      id="dropdownMenuButton1"
                      to="https://xtrcswap.vercel.app/"
                    >
                      Sell XTRC
                    </Link>
                  )}
                  <Link
                    class="btn primary_btn"
                    target="_blank"
                    style={{ marginLeft: "12px" }}
                    type="button"
                    id="dropdownMenuButton1"
                    to="https://solidity-lottery-ten.vercel.app/"
                  >
                    TRC Lotto
                  </Link>

                  {/* <li class="nav-item">
                    <a class="nav-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M8 8.6449C10.5247 8.6449 12.5714 6.72789 12.5714 4.36314C12.5714 1.99839 10.5247 0.0813751 8 0.0813751C5.47527 0.0813751 3.42857 1.99839 3.42857 4.36314C3.42857 6.72789 5.47527 8.6449 8 8.6449Z"
                          fill="#E9D16F"
                        />
                        <path
                          d="M1.71429 16.138H8.77714C7.77376 15.3847 7.10931 14.3057 6.91561 13.115C6.72191 11.9244 7.0131 10.709 7.73143 9.70999C6.25415 9.64125 4.8622 9.04114 3.84571 8.03475C2.70623 8.48529 1.73375 9.24034 1.04978 10.2056C0.365812 11.1708 0.000678157 12.3034 0 13.4619V14.5323C0.00135662 14.9578 0.182404 15.3654 0.503601 15.6663C0.824799 15.9671 1.26005 16.1367 1.71429 16.138Z"
                          fill="#E9D16F"
                        />
                        <path
                          d="M8 12.3914C8 13.1324 8.23459 13.8568 8.67412 14.4729C9.11365 15.089 9.73836 15.5692 10.4693 15.8528C11.2002 16.1364 12.0044 16.2106 12.7804 16.066C13.5563 15.9214 14.269 15.5646 14.8284 15.0406C15.3878 14.5167 15.7688 13.8491 15.9231 13.1224C16.0775 12.3956 15.9983 11.6423 15.6955 10.9577C15.3928 10.2731 14.8801 9.68798 14.2223 9.27631C13.5645 8.86463 12.7911 8.6449 12 8.6449C10.9395 8.64603 9.92279 9.04112 9.17291 9.74349C8.42302 10.4459 8.00121 11.3981 8 12.3914ZM12.5714 11.321V11.8562H13.1429C13.2944 11.8562 13.4398 11.9126 13.5469 12.013C13.6541 12.1134 13.7143 12.2495 13.7143 12.3914C13.7143 12.5334 13.6541 12.6695 13.5469 12.7699C13.4398 12.8703 13.2944 12.9267 13.1429 12.9267H12.5714V13.4619C12.5714 13.6038 12.5112 13.74 12.4041 13.8403C12.2969 13.9407 12.1516 13.9971 12 13.9971C11.8484 13.9971 11.7031 13.9407 11.5959 13.8403C11.4888 13.74 11.4286 13.6038 11.4286 13.4619V12.9267H10.8571C10.7056 12.9267 10.5602 12.8703 10.4531 12.7699C10.3459 12.6695 10.2857 12.5334 10.2857 12.3914C10.2857 12.2495 10.3459 12.1134 10.4531 12.013C10.5602 11.9126 10.7056 11.8562 10.8571 11.8562H11.4286V11.321C11.4286 11.1791 11.4888 11.0429 11.5959 10.9425C11.7031 10.8422 11.8484 10.7858 12 10.7858C12.1516 10.7858 12.2969 10.8422 12.4041 10.9425C12.5112 11.0429 12.5714 11.1791 12.5714 11.321Z"
                          fill="#E9D16F"
                        />
                      </svg>
                      Join Community
                    </a>
                  </li> */}
                </ul>

                {/* <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  Connect Wallet
                </button>  */}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
        data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {connectors &&
                    connectors.length > 0 &&
                    connectors.map((connector) => (
                      <>
                        {connector && connector.id == "metaMask" ? (
                          <a target="_blank" className="mb-3 d-block">
                            <button
                              type="button"
                              className="connect_wallet_button"
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/metamask.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span>MetaMask</span>
                            </button>
                          </a>
                        ) : (
                          ""
                        )}
                        {connector && connector.id == "walletConnect" ? (
                          <a target="_blank" className="mb-3 d-block">
                            <button
                              type="button"
                              className="connect_wallet_button"
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/trustwallet.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span>Trust Wallet</span>
                            </button>
                          </a>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
