import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes
} from "react-router-dom";
import "./App.css";

import Home from "./pages/home.js";
import Dashboard_member from "./pages/dashboard_member";
import Dashboard_member1 from "./pages/dashboard_member1";
import Joinnow from "./pages/join-now.js";
import Dashboard_community from "./pages/dashboard_community.js";
import Dashboard_community1 from "./pages/dashboard_community1.js";
import Jashboard_community from "./pages/joincommunity";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { Provider } from "react-redux";
import store from "./store";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import {
  bsc,
  bscTestnet,
  polygon,
  polygonMumbai,
  sepolia,
  mainnet
} from "viem/chains";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet],
  [publicProvider()]
);
console.log({ chains }, "chainschainschainswwwww");

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true
    }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'Web3modalv2',
    //     jsonRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/N55E4OBB31jjeOw9d6FBUVebdYu3chSY',
    //   },
    // }),
    new WalletConnectConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
      options: {
        projectId: "681700e231a5aef269b7fe4adb34981a",
        version: "2"
      }
    }),
    new InjectedConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
      options: {
        name: "Injected"
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "dashboard-member", element: <Dashboard_member /> },
    { path: "dashboard-community", element: <Dashboard_community /> },
    { path: "dashboard-member/:addr", element: <Dashboard_member1 /> },
    { path: "dashboard-community/:addr", element: <Dashboard_community1 /> },
    { path: "join-now", element: <Joinnow /> },
    { path: "join-now/:id", element: <Joinnow /> },
    // { path: "join-community", element: <Jashboard_community /> },
    // { path: "join-community/:id", element: <Jashboard_community /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <WagmiConfig config={config}>
        <ToastContainer />
        <Router basename="/">
          <App />
        </Router>
      </WagmiConfig>
    </Provider>
  );
};

export default AppWrapper;
